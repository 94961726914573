<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="close">
    <v-card>
      <v-tabs v-model="tab_details">
        <v-tab dense :value="i" v-for="(y,i) in [...new Set(estudios.map(item => item.year))].sort((a, b) => b - a)" :key="i">{{ y }}</v-tab>
      </v-tabs>
      <v-tabs-items
        style="font-size: 0.9rem;flex-grow: 1;"
        class="py-1"
        v-model="tab_details"
      >
        <v-tab-item :value="i" v-for="(y,i) in [...new Set(estudios.map(item => item.year))].sort((a, b) => b - a)" :key="i">
          <div @contextmenu.prevent="setEstudioPrincipal(y,e)" @click="addEstudio(y,e)" :style="'cursor: pointer;border-bottom: 1px solid lightgray;margin-top:2px; margin-bottom: 2px;' + (adding[0] == e ? 'background-color: var(--v-info-base)' : '')" v-for="e in estudios.filter(es => es.year == y)" class="d-flex justify-start align-center hOption">
            <v-checkbox :value="adding.some(a => a.year == e.year && a.cif == e.cif)" class="ma-0" hide-details></v-checkbox>{{ e.nombre }}
          </div>
        </v-tab-item>
      </v-tabs-items>
      <div class="d-flex justify-end align-center">
        <v-btn @click.stop="adding = []" text color="info">REINICIAR</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click.stop="close" text color="error">CERRAR</v-btn>
        <v-btn @click.stop="agree" text color="primary">GUARDAR</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props:['estudios','comparadas'],
  components: {
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  data: () => ({
    tab_details: new Date().getFullYear()-1,
    adding:[],
    dialog: false,
    resolve: null,
    reject: null,
    options: {
      color: 'primary',
      width: 800,
      zIndex: 200
    }
  }),
  methods: {
    setEstudioPrincipal(y,e){
      var ind = this.adding.findIndex(a => a.year == y && a.cif == e.cif)
      if (ind != -1) this.adding.splice(ind,1)
      this.adding.unshift(e)
    },
    addEstudio(y,e){
      var ind = this.adding.findIndex(a => a.year == y && a.cif == e.cif)
      if (ind == -1) this.adding.push(e)
      else this.adding.splice(ind,1)
    },
    open() {
      this.dialog = true
      this.adding = JSON.parse(JSON.stringify(this.comparadas))
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close() {
      this.resolve(false)
      this.adding = []
      this.dialog = false
    },
    agree() {
      this.resolve(this.adding)
      this.adding = []
      this.dialog = false
    }
  }
}
</script>

<style>
.hOption:hover{
  background-color: rgb(144, 144, 144);
}
.v-input--selection-controls.v-input{
  padding: 0;
}
</style>